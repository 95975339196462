const MESSAGE = {
  EN: {
    zone_header: 'ZONE',
    zone_subheader: 'ZONE',
    action_subheader: 'DETECTION TYPE',
    target_subheader: 'TARGET',
    add_zone: 'Add Zone',
    Unauthorized: 'Unauthorized',
    'Color Violation': 'Color Violation',
    person: 'Person',
    truck: 'Truck'
  },
  TH: {
    zone_header: 'โซน',
    zone_subheader: 'โซน',
    action_subheader: 'ประเภทการตรวจพบ',
    target_subheader: 'เป้าหมาย',
    add_zone: 'เพิ่มโซน',
    Unauthorized: 'ไม่อนุญาตให้เข้า',
    'Color Violation': 'สี',
    person: 'บุคคล',
    truck: 'รถบรรทุก'
  }
}

export default MESSAGE
