import styled from 'styled-components'

export default styled.div`
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .zone-row-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: white;
    padding: 10px;
    padding-bottom: 0;
    max-height: 441px;
    overflow-y: auto;
    .zone-row-wrapper {
      display: grid;
      grid-template-columns: 20% 80%;
      grid-auto-rows: 65px;
      border-radius: 5px;
      border: 2px solid transparent;

      .zone-srcshot {
        place-self: center;
        border-radius: 5px;
        canvas {
          border-radius: 5px;
        }
      }
      .zone-detail-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        .zone-content {
          padding-top: 4px;
          color: ${(props) => props.theme.darkBackground};
          place-self: center;
          .zone-subheader {
            font-size: 10px;
            font-weight: bold;
            line-height: 0.63;
            opacity: 0.5;
            margin-bottom: 8px;
          }
          .zone-subcontent {
            font-size: 18px;
            font-weight: bold;
            &.features,
            &.violation {
              width: 150px;
            }
          }
          &.violations {
            justify-self: start;
          }
          &.targets {
            justify-self: start;
          }
        }
        .delete-zone-btn-wrapper {
          place-self: center;
          &:hover {
            cursor: pointer;
          }
        }
      }
      &:hover {
        cursor: pointer;
      }
      &.disabled {
        .add-zone-btn {
          background: ${(props) => props.theme.greyDisabled};
        }
        &:hover {
          cursor: not-allowed;
        }
      }
    }
    .active {
      background: rgba(39, 164, 72, 0.1);
      border-radius: 5px;
      border: 2px solid ${(props) => props.theme.jadeGreen};
      &:hover {
        cursor: default;
      }
    }
    .add-zone-btn {
      width: 65px;
      height: 45px;
      background: ${(props) => props.theme.jadeGreen};
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      place-self: center;
    }
    .add-zone-msg {
      font-family: 'Prompt', sans-serif;
      font-size: 18px;
      font-weight: bold;
      color: ${(props) => props.theme.darkBackground};
      padding-top: 16px;
      padding-left: 46px;
    }
  }
`
