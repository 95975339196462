import React from 'react'
import PropTypes from 'prop-types'
import * as PIXI from 'pixi.js'
import Tooltip from '@material-ui/core/Tooltip'
import ZoneEditingStyled from './styledComponent'
import ZonePolygon from '../ZonePolygon/Loadable'
import TrashIcon from '../../asset/images/button/trash.svg'
import AddIcon from '../../asset/images/button/add.svg'
import MESSAGE from './message'

import { PERSON_TARGET, UNAUTHORIZED_EVENT, LIST_VEHICLE_CLASS, CAR_TARGET, makeFirstLetterCapital } from '../../utils'

class ZoneEditingCard extends React.PureComponent {
  componentDidMount() {
    PIXI.utils.skipHello()
  }

  setActiveZone(zone) {
    this.props.setActiveZone(zone)
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  onDeleteZoneClick(zoneId) {
    this.props.deleteZoneClick(zoneId)
  }

  convertPointsDataArrayObjectXY(arrPoints) {
    const tempCoordinates = arrPoints.map(([x, y]) => new PIXI.Point(x, y))
    if (arrPoints.length > 0) {
      const [x, y] = arrPoints[0]
      tempCoordinates.push(new PIXI.Point(x, y))
    }
    return tempCoordinates
  }

  handleAddZoneClicked() {
    this.props.onAddZone()
  }

  generateListTargetColor(colorRanges) {
    if (colorRanges && colorRanges.length > 0) {
      return colorRanges
        .map((color) => {
          return color.range_name || '-'
        })
        .join(', ')
    } else {
      return '-'
    }
  }

  getTargetOfEachZone(zone) {
    const targetClassName = zone.features[0] ? zone.features[0].class_type : '-'
    if (targetClassName === '') {
      return '-'
    }
    if (zone.violation_name === UNAUTHORIZED_EVENT) {
      return this.getMessage(targetClassName)
    } else {
      return this.generateListTargetColor(zone.color_range)
    }
  }

  getContent() {
    let zoneRows = this.props.data.zones.map((zone, index) => {
      const coordinates = this.convertPointsDataArrayObjectXY(zone.points)
      const active = this.props.activeZone && zone.zone_id === this.props.activeZone.zone_id ? 'active ' : ''
      const zoneId = zone.zone_id ? zone.zone_id : '-'
      const violationName = zone.violation_name ? this.getMessage(zone.violation_name) : '-'
      const finalFeatureName = this.getTargetOfEachZone(zone)
      return (
        <div className={`zone-row-wrapper ${active}`} key={zone.zone_id} onClick={() => (!active ? this.setActiveZone(zone) : null)}>
          <div className="zone-srcshot">
            <ZonePolygon
              zoneIterator={index}
              zoneData={zone}
              coordinates={coordinates}
              resolutionWidth={this.props.data.resolution_width}
              resolutionHeight={this.props.data.resolution_height}
            />
          </div>
          <div className="zone-detail-wrapper">
            <div className="zone-content">
              <div className="zone-subheader text-style">{this.getMessage('zone_subheader')}</div>
              <div className="zone-subcontent text-style">{zoneId}</div>
            </div>
            <div className="zone-content violations">
              <div className="zone-subheader text-style">{this.getMessage('action_subheader')}</div>
              <div className="zone-subcontent violation text-style">{violationName}</div>
            </div>
            <div className="zone-content targets">
              <div className="zone-subheader text-style">{this.getMessage('target_subheader')}</div>
              <Tooltip title={finalFeatureName}>
                <div className="zone-subcontent text-style features prevent-text-overflow">{finalFeatureName}</div>
              </Tooltip>
            </div>
            <div className="delete-zone-btn-wrapper" onClick={() => this.onDeleteZoneClick(zone.zone_id)}>
              <img src={TrashIcon} alt="delete button" onClick={() => this.onDeleteZoneClick(zone.zone_id)} />
            </div>
          </div>
        </div>
      )
    })
    let disabledAddZoneBtn = this.props.shouldDisabledAddZoneBtn ? ' disabled' : ''
    let addButtonRow = (
      <div
        className={'zone-row-wrapper' + disabledAddZoneBtn}
        onClick={() => (this.props.shouldDisabledAddZoneBtn ? null : this.handleAddZoneClicked())}
      >
        <div className="add-zone-btn">
          <img src={AddIcon} alt="" />
        </div>
        <div className="add-zone-msg">{this.getMessage('add_zone')}</div>
      </div>
    )

    return (
      <div className="zone-row-container">
        {zoneRows}
        {addButtonRow}
      </div>
    )
  }

  render() {
    return <ZoneEditingStyled>{this.getContent()}</ZoneEditingStyled>
  }
}

ZoneEditingCard.defaultProps = {
  language: 'EN'
}

ZoneEditingCard.propTypes = {
  data: PropTypes.shape({
    area_id: PropTypes.number,
    area_name: PropTypes.string,
    camera_id: PropTypes.number,
    camera_status: PropTypes.string,
    health_check_time: PropTypes.string,
    image: PropTypes.string,
    is_active: PropTypes.bool,
    name: PropTypes.string,
    project_id: PropTypes.number,
    resolution_height: PropTypes.number,
    resolution_width: PropTypes.number,
    site_id: PropTypes.number,
    zones: PropTypes.arrayOf(
      PropTypes.shape({
        allowed: PropTypes.bool,
        camera_id: PropTypes.number,
        grid_color: PropTypes.string,
        features: PropTypes.arrayOf(
          PropTypes.shape({
            class_confidence: PropTypes.number,
            class_id: PropTypes.number,
            class_name: PropTypes.string,
            color_confidence: PropTypes.number,
            color_id: PropTypes.number,
            color_name: PropTypes.string
          })
        ),
        points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
        violation_id: PropTypes.number,
        violation_name: PropTypes.string,
        zone_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }).isRequired,
  activeZone: PropTypes.shape({
    allowed: PropTypes.bool,
    camera_id: PropTypes.number,
    grid_color: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        class_confidence: PropTypes.number,
        class_id: PropTypes.number,
        class_name: PropTypes.string,
        color_confidence: PropTypes.number,
        color_id: PropTypes.number,
        color_name: PropTypes.string
      })
    ),
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    violation_id: PropTypes.number,
    violation_name: PropTypes.string,
    zone_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  deleteZoneClick: PropTypes.func.isRequired,
  language: PropTypes.string,
  setActiveZone: PropTypes.func,
  onAddZone: PropTypes.func.isRequired,
  shouldDisabledAddZoneBtn: PropTypes.bool
}

export default ZoneEditingCard
